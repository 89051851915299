.worksContainer {
  margin: 11rem;
  position: relative;
  overflow: hidden;

  h1 {
    margin-bottom: 1rem;
  }

  .workgrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }

  .work {
    border: none;
    background-color: transparent;
    outline: 0;
    overflow: hidden;

    img {
      width: 30rem;
    }
    h3 {
      position: absolute;
      opacity: 0;
      text-align: center;
      justify-content: center;
      align-items: center;
      padding-top: 15%;
      color: white;
      background: rgba(0, 0, 0, 0.8);
    }

    &:hover {
      cursor: pointer;
      border: none;
      background-color: transparent;
      overflow: hidden;

      img {
        transform: scale(1.4);
        transition: all 1s;
        z-index: 1;
      }
      h3 {
        opacity: 1;
        cursor: pointer;
        transition: all 800ms;
        z-index: 2;
        width: 52%;
        height: 24%;
      }
    }

    &:focus {
      text-decoration: none;
      outline: 0;
      border: none;
      color: #808080;
      background-color: transparent;
      box-shadow: none;
      overflow: hidden;
    }
  }
}

@media only screen and (max-width: 768px) {
  .worksContainer {
    margin: 11rem 4rem;

    .workgrid {
      display: block;
      justify-content: center;

      .work {
        margin: 1rem 0;
        img {
          width: 100%;
        }

        &:hover {
          h3 {
            padding-top: 30%;
            width: 100%;
            height: 12.2%;
          }
        }
      }
    }
  }
}
