* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Fira Code", monospace;
  background-color: #f2f4f9;
  color: #2c3538;
  margin: 2% 5%;
  .link {
    text-decoration: none;
    color: #2c3538;
  }
}
