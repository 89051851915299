.detailContainer {
  margin: 5rem 11rem;

  h1 {
    margin-bottom: 1rem;
  }

  h3 {
    font-weight: 200;
    margin-bottom: 2rem;
  }
  img {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .detailContainer {
    margin: 1rem 4rem 8rem 4rem;
  }
}
